import { useState } from 'react'
import { GiHamburgerMenu } from 'react-icons/gi'
import './MainHeaderNavMenuMobile.css'
import ButtonNav from '../../ButtonNav/ButtonNav';
import { IconContext } from 'react-icons/lib';
import { useHistory } from "react-router-dom";


function MainHeaderNavMenuMobile ({
    scrollCallBackServices=f=>f, 
    scrollCallBackInsights=f=>f,
    scrollCallBackAboutUs=f=>f,
    scrollCallBackContactUs=f=>f
}) {
    
    const [isVisible, setIsVisible] = useState(false);
    let history = useHistory();

    const toggleMenu = () => {
        if (isVisible === false) {
            setIsVisible(true)
        } else {
            setIsVisible(false)
        }
    }

    const onClick = () => {
        history.push(`/client-login`)
    }

    const onServicesLinkClick = () => {
        scrollCallBackServices()
        toggleMenu()
    }

    const onInsightsLinkClick = () => {
        scrollCallBackInsights()
        toggleMenu()
    }

    const onAboutUsLinkClick = () => {
        scrollCallBackAboutUs()
        toggleMenu()
    }

    const onContactUsLinkClick = () => {
        scrollCallBackContactUs()
        toggleMenu()
    }

    return (
        <>
            <div className="hamburger-menu-container" onClick={() => toggleMenu()}>
                <IconContext.Provider value={{ color: "white" ,size: "2.5em"}}>
                    <GiHamburgerMenu />
                </IconContext.Provider>
            </div>
                { isVisible ?
                    <div className="main-header-nav-menu-mobile-container">
                        <div className="link-container" onClick={() => onServicesLinkClick()}>
                            Services
                        </div>
                        <div className="link-container" onClick={() => onInsightsLinkClick()}>
                            Insights
                        </div>
                        <div className="link-container" onClick={() => onAboutUsLinkClick()}>
                            About Us
                        </div>
                        <div className="link-container" onClick={() => onContactUsLinkClick()}>
                            Contact Us
                        </div>
                        <ButtonNav text={"Client Login"} onClick={() => onClick()}/>
                    </div>
                :
                    null
                }
        </>
    )
}

export default MainHeaderNavMenuMobile