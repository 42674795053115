import './RecentInsightsCard.css'
import { layoutGenerator } from 'react-break'
import { useHistory } from 'react-router'
import { MdKeyboardArrowRight } from 'react-icons/md'
import { cropText } from '../../../lib/HelperFunctions'

const layout = layoutGenerator({
    mobile: 0,
    tablet: 556,
    desktop: 1550
})

const OnMobile = layout.is('mobile')
const OnTablet = layout.is('tablet')
const OnDesktop = layout.is('desktop')


function RecentInsightsCard({ image, category, title, bodyText, articleId }) {

    let history = useHistory();

    function handleClick() {
        history.push(`/insights/${articleId}`)
    }

    return (
        <>
            <OnDesktop>
                <div className="recent-insights-card-container-desktop">
                    <div className="recent-insights-card-image-desktop">
                        <img src={image} alt="to-enter"/>
                    </div>
                    <div className="recent-insights-card-category-desktop">
                        {category}
                    </div>
                    <div className="recent-insights-card-title-desktop">
                        {title}
                    </div>
                    <div className="recent-insights-card-bodyText-desktop">
                        {cropText(bodyText, 140)}
                    </div>
                    <div className="recent-insights-card-link-desktop" onClick={handleClick}>
                        <span>Read more</span> 
                        <span style={{ paddingTop: "8px" }}>
                            <MdKeyboardArrowRight/>
                        </span>
                    </div>
                </div>
            </OnDesktop>

            <OnTablet>
                <div className="recent-insights-card-container-tablet">
                    <div className="recent-insights-card-image-tablet">
                        <img src={image} alt="to-enter"/>
                    </div>
                    <div className="recent-insights-card-category-tablet">
                        {category}
                    </div>
                    <div className="recent-insights-card-title-tablet">
                        {title}
                    </div>
                    <div className="recent-insights-card-bodyText-tablet">
                        {cropText(bodyText, 140)}
                    </div>
                    <div className="recent-insights-card-link-tablet" onClick={handleClick}>
                        Read more 
                        <div style={{ paddingTop: "6px" }}>
                            <MdKeyboardArrowRight/>
                        </div>
                    </div>
                </div>
            </OnTablet>

            <OnMobile>
                <div className="recent-insights-card-container-mobile">
                    <div className="recent-insights-card-image-mobile">
                        <img src={image} alt="to-enter"/>
                    </div>
                    <div className="recent-insights-card-category-mobile">
                        {category}
                    </div>
                    <div className="recent-insights-card-title-mobile">
                        {title}
                    </div>
                    <div className="recent-insights-card-link-mobile" onClick={handleClick}>
                        Read more 
                        <div style={{ paddingTop: "6px" }}>
                            <MdKeyboardArrowRight/>
                        </div>
                    </div>
                </div>
            </OnMobile>
        </>
    )
}

export default RecentInsightsCard