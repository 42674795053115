import { useEffect, useState } from "react"
import { useLocation } from 'react-router-dom'
import MainHeaderNavNoMenu from "../../MainHeaderNavNoMenu/MainHeaderNavNoMenu"
import sustainableInvestingArticleImage from "./SustainableInvestingArticle/SustainableInvestingArticleImage.js"
import './SustainableInvesting.css'
import ArticleBody from '../Article/ArticleBody/ArticleBody.js'
import Footer from "../../Footer/Footer"

function SustainableInvesting() {

    const [imageLoaded, setImageLoaded] = useState(false)
    const { pathname } = useLocation();

    const category = "INVESTMENT MANAGEMENT"
    const title = "How Strong Are Asian Markets?"
    const subTitle = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Libero pellentesque aliquam ut dui netus viverra. Risus, cursus eu placerat molestie at quis."
    const date = "OCT 10, 2021"
    const bodyText = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet, enim, cursus sapien blandit pretium. Nunc blandit enim euismod nisl, nisi. Lacus pretium, vel amet euismod risus risus. Vitae penatibus quis viverra sit. Molestie in mauris mauris egestas amet purus arcu blandit. Non porttitor facilisi pulvinar lorem nibh. Nibh eu amet mi nunc placerat lobortis vitae at. Amet, convallis egestas purus feugiat. Hendrerit in amet, mollis sed sem. At scelerisque aliquam, aliquam, egestas quis nulla ac tincidunt. Scelerisque tortor justo volutpat malesuada.Et nunc purus malesuada risus ut urna, quis quis adipiscing. Cras proin diam aliquam integer. Eget dui quisque tellus massa enim. Purus neque leo sed iaculis. Faucibus porttitor rutrum pulvinar at penatibus. Morbi dictum nulla tempus tincidunt interdum integer. Proin lectus tempus a tempus urna euismod in. Quam porttitor ut donec porttitor. Morbi hendrerit convallis venenatis vitae velit adipiscing. Nibh posuere in vel luctus facilisi libero.Neque quam dui sagittis sed pharetra, odio. Pharetra aenean cursus egestas gravida augue. Quam nibh in pretium cum velit est. Tincidunt vitae ut quam aliquet. Scelerisque aenean tortor dui at dapibus pellentesque habitant turpis. Mi erat dictum nisi, facilisi id posuere tristique in sed. Volutpat, nibh feugiat semper aenean arcu eget massa. Parturient vulputate dictumst justo, commodo egestas.Viverra quis quis neque integer laoreet quis vestibulum. In eget vestibulum, adipiscing massa, rhoncus. Diam eu integer orci eu tincidunt."

    useEffect(() => {
        window.scrollTo(0,0);
    }, [pathname]);


    return(
        <div className="sustainable-investing-container-center">
            <MainHeaderNavNoMenu/>
            <div className="sustainable-investing-layout-grid">
                <div className="sustainable-investing-image-wrapper">
                    {!imageLoaded ? (
                        <>
                            Loading article
                        </>
                    ) : (
                        <>
                            <img
                                src={sustainableInvestingArticleImage}
                                alt="large icebergs"
                                className="sustainable-investing-article-image"
                                style={!imageLoaded ? { visibility: 'hidden'} : {}}
                                onLoad={() => setImageLoaded(true)}
                            />
                        </>
                    )}
                </div>
                <div className="sustainable-investing-article-container">
                    <ArticleBody category={category} title={title} subtitle={subTitle} date={date} bodyText={bodyText}/>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default SustainableInvesting