import React, {useEffect, useState} from "react";
import { 
  BrowserRouter as Router,
  Switch,
  Route,
}  from 'react-router-dom'
import axios from "axios";
import imageAdam from './static/img/imageAdam.png';
import imageMartin from './static/img/imageMartin.png';
import imageMark from './static/img/imageMark.png';
import imageAdamMobile from './static/img/imageAdamMobile.png';
import imageMartinMobile from './static/img/imageMartinMobile.png';
import imageMarkMobile from './static/img/imageMarkMobile.png';
import MainSite from "./components/MainSite/MainSite.js";
import SustainableInvesting from "./components/MainSite/Pages/SustainableInvesting/SustainableInvesting.js";
import Insights from "./components/MainSite/Pages/Insights/Insights.js";
import Article from "./components/MainSite/Pages/Article/Article.js";
import ClientLogin from "./components/MainSite/Pages/ClientLogin/ClientLogin.js";
import Privacy from "./components/MainSite/Pages/Privacy/Privacy.js";
import './globals.css';

export const ARTICLE_SERVICE_URL = "https://wmiqworw69.execute-api.eu-west-2.amazonaws.com/insights"

function App() {

  const [data, setData] = useState({articles:[], isFetching: false});
  const [dataFetched, setDataFetched] = useState(false)

  useEffect(() => {
    const controller = new AbortController();
    const fetchArticles = async () => {
      try {
        setData({articles: data.articles, isFetching: true});
        const response = await axios.get(`https://wmiqworw69.execute-api.eu-west-2.amazonaws.com/insights/front-page`, {
          signal: controller.signal
        });
        setData({articles: response.data, isFetching: false});
        setDataFetched(true)
      } catch (e) {
        console.log(e);
        setData({articles: data.articles, isFetching: false});
      }
    };
    if (dataFetched === false && data.articles.length === 0) {
      fetchArticles();
    }
    return () => {
      controller.abort();
    }
  }, [dataFetched]);

  const props = {
    ...mainSiteData,
    articles: data.articles
  }

  return (
      <>
        <Router>
            <Switch>
              <Route exact path="/">
                <MainSite {...props} />
              </Route>
              <Route path="/client-login">
                <ClientLogin/>
              </Route>
              <Route path="/sustainableinvesting">
                <SustainableInvesting/>
              </Route>
              <Route path="/insights/:id">
                <Article/>
              </Route>
              <Route path="/insights">
                <Insights/>
              </Route>
              <Route path="/privacy">
                <Privacy/>
              </Route>
            </Switch>
        </Router>
      </>
  );
}

export default App;

const heroCardAboutUsData = {
  title: "About Us",
  bodyText: "Find out more about our team.",
  linkText: "About Us"
}

const heroCardSustainableInvestingData = {
  title: "Socially Responsible Investing",
  bodyText: "Find out more on our Socially Responsible Investment methods.",
  linkText: "Socially Responsible Investing"
}

const heroCardInsightsData = {
  title: "Insights",
  bodyText: "Read our latest views on markets, the global economy and tax planning.",
  linkText: "Insights"
}

const heroCardData = {
  heroCardAboutUsData: heroCardAboutUsData,
  heroCardSustainableInvestingData: heroCardSustainableInvestingData,
  heroCardInsightsData: heroCardInsightsData
}

const servicesCardFinancialWealthPlanning = {
  titleLineOne: "Family Wealth",
  titleLineTwo: "Planning",
  bodyText: `Managing wealth with your whole family in mind is a complex and often daunting task.\n We approach financial planning from a intergenerational perspective and we offer services to help ensure your financial affairs are in order alongside those of your wider family.`
}

const servicesCardInvestmentManagement = {
  titleLineOne: "Portfolio",
  titleLineTwo: "Construction",
  bodyText: "Drawing on best in class research, our portfolio construction team build and monitor portfolios to help deliver stable long term returns within your investment vehicles.\n  Crucially, Grafton Court is fiercely independent, relentlessly seeking secure investment opportunties with proven track records of added value."
}

const servicesRetirementPlanning = {
  titleLineOne: "Retirement",
  titleLineTwo: "Planning",
  bodyText: "One of the most pivotal moments in any individuals life is the point where you decide to take a step back from the world of work. \n We use cashflow modelling to visually present your financial retirement journey; coupled with our portfolio construction services to target stable long term returns, we are confident we can support your retirement journey and goals. "
}

const servicesInheritanceTaxPlanning = {
  titleLineOne: "Inheritance Tax",
  titleLineTwo: "Planning",
  bodyText: "Our team are qualified in the myriad of financial tools which can be used to reduce a potential Inheritance Tax liability. \n These tools include utilisation of Trusts, insurances or simply advice on gifting to children or charities."
}

const servicesSociallyResponsibleInvesting = {
  titleLineOne: "Socially Responsible",
  titleLineTwo: "Investing",
  bodyText: "Socially Responsible Investing, or SRI, involves investing while considering the broader social impact of the companies and financial instruments in use.\n When it comes to selecting SRI investments, we remain committed to our core principles, which include conducting independent research and directing our resources towards investments that have a well-established history of creating positive value."  
}

const servicesCorporateWealth = {
  titleLineOne: "Corporate Wealth",
  titleLineTwo: "Planning",
  bodyText: "We have extensive experience of dealing with business owners and entrepreneurs keen to incorprate the wealth they have built within their business into their family wealth picture.  \nBe it helping secure a growing business or exiting an existing one we have the connections and team to help you maximise this valuable asset."
}

const servicesCardData = {
  servicesCardFinancialWealthPlanning: servicesCardFinancialWealthPlanning,
  servicesCardInvestmentManagement: servicesCardInvestmentManagement,
  servicesRetirementPlanning: servicesRetirementPlanning,
  servicesInheritanceTaxPlanning: servicesInheritanceTaxPlanning,
  servicesSociallyResponsibleInvesting: servicesSociallyResponsibleInvesting,
  servicesCorporateWealth: servicesCorporateWealth
}

const professionalTitlesMartin = [
  "Director"
]

const aboutUsMartin = {
  image: imageMartin,
  imageMobile: imageMartinMobile,
  name: "Martin Walls",
  letters: "DipPFS",
  professionalTitles: professionalTitlesMartin,
  bodyText: "With over 35 years experience working and advising clients in various firms from a London stockbrokers to running his own firm of independent advisers, Martin enjoys providing bespoke, detailed and impartial financial planning advice to business, individuals and families."
}

const professionalTitlesMark = [
  "Director"
]

const aboutUsMark = {
  image: imageMark,
  imageMobile: imageMarkMobile,
  name: "Mark Ashworth",
  letters: "Chartered MCSI FPFS",
  professionalTitles: professionalTitlesMark,
  bodyText: "Mark is both a Chartered Wealth Manager and Chartered Financial Planner which demonstrates his highly sophisticated understanding of complex technical financial concepts.  \nThis wealth of knowledge ensures seamless combining of financial planning and portfolio construction needs for clients."
}

const professionalTitlesAdam = [
  "Finanical Planner"
]

const aboutUsAdam = {
  image: imageAdam,
  imageMobile: imageAdamMobile,
  name: "Adam Price",
  letters: "DipPFS",
  professionalTitles: professionalTitlesAdam,
  bodyText: "Adam is a Financial Planner with over 10 years in the industry, taking up a variety of roles within the financial planning process.  This has allowed him to gain broad technical knowledge and a well-rounded view of financial planning.  \nHe provides advice to a range of clients from individuals starting to consider their future financial position to established business owners and high-net worth client families."
}

const aboutUsData = {
  martin: aboutUsMartin,
  mark: aboutUsMark,
  adam: aboutUsAdam
}

const mainSiteData = {
  heroCardData: heroCardData,
  servicesCardData: servicesCardData,
  aboutUsData: aboutUsData
};