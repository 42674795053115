import './ServicesCard.css'
import { formatText } from '../../../lib/HelperFunctions'

function ServicesCard({titleLineOne, titleLineTwo, bodyText, onClickContactUs=f=>f}) {

    return (
        <div className="services-card-container">
            <div className="serivces-card-titleLineOne-container">
                <div>{titleLineOne}</div>
            </div>
            <div className="services-card-titleLineTwo-container">
                <div>{titleLineTwo}</div>
            </div>
            <div className="services-card-underline">
                <div className="underline"></div>
            </div>
            <div className="services-card-bodyText-container">
                <div>{formatText(bodyText)}</div>
            </div>
            <div className="services-card-contact-us-button-container" onClick={() => onClickContactUs()}>
                Contact Us
            </div>
        </div>
    )
}

export default ServicesCard