import { formatText } from '../../../../../lib/HelperFunctions'
import './ArticleBody.css'

function ArticleBody({ category, title, subtitle, date, bodyText }) {

    const formattedText = formatText(bodyText)

    return (
        <div className="main-article-body-container">
            <div className="article-category">
                {category}
            </div>
            <div className="article-title">
                {title}
            </div>
            <div className="article-subtitle">
                {subtitle}
            </div>
            <div className="article-line-divider"/>
            <div className="article-date">
                {date}
            </div>
            <div className="article-body">
                {formattedText}
            </div>
            <div className="disclaimer-container">
                <div className='disclaimer-title'>
                    Disclaimer 
                </div>
                <div className='disclaimer-body'>
                    Our insights offer general information only and are not tailored to your situation. You should not depend on our insights without taking advice based on the full facts of your personal situation, for example from our advisers. 
                    
                    Where our insights refer to investments, please remember that investments can go up and down in value, so you could get back less than you put in and do not constitutive a recommendation to investment.  You should always seek personalised advice from a qualified, regulated individual
                </div>
            </div>
        </div>
    )
}

export default ArticleBody