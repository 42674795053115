import { formatText } from '../../../lib/HelperFunctions'
import './AboutUsCard.css'

function AboutUsCard ({image, name, letters, professionalTitles, bodyText}) {


    return (
        <div className="about-us-card-container">
            <div className="about-us-image">
                <img src={image} alt={name}/>
            </div>
            <div className="about-us-title">
                <span className="about-us-name">
                    {name}
                </span>
                <span className="about-us-letters">
                    {letters}
                </span>
            </div>
            <div className="about-us-professional-titles-container">
                {professionalTitles.map((title, index) => {
                    return (
                        <div className="about-us-professional-title" key={index}>
                            {title}
                        </div>
                    )
                })}
            </div>
            <div className="about-us-bodyText">
                {formatText(bodyText)}
            </div>
        </div>
    )
}

export default AboutUsCard 