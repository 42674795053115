import { useState } from "react"
import { IconContext } from "react-icons"
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md"
import { BsDot } from "react-icons/bs"
import { GiPauseButton, GiPlayButton } from "react-icons/gi"
import './CaroselControl.css'


function CarouselControl({ prevSlide=f=>f, nextSlide=f=>f, play=f=>f, pause=f=>f, currentSlide, intervalId, seconds }) {

    const [pauseActive, setPauseActive] = useState(true);

    const handlePauseClick = () => {
        setPauseActive(false)
        pause()
    }

    const handlePlayClick = () => {
        setPauseActive(true)
        play()
    }

    return (
        <div className="carousel-control-container">
            <div className="left-arrow-container">
                <IconContext.Provider value={{ size: "1.5em"}}>
                    <MdKeyboardArrowLeft className="left-arrow" onClick={prevSlide}/>
                </IconContext.Provider>
            </div>
            <div className="control-dots-container">
                {(pauseActive === true) ? (
                    <div className="pause-play-container" onClick={() => handlePauseClick()}>
                        <GiPauseButton/>
                    </div>
                ) : (
                    <div className="pause-play-container" onClick={() => handlePlayClick()}>
                        <GiPlayButton />
                    </div>
                )}
                <div className="dot-container-0">
                    {(currentSlide === 0) ? (
                        <IconContext.Provider value={{ color: "#00531D", size: "1.5em"}}>
                            <BsDot/> 
                        </IconContext.Provider>
                    ) : (
                        <IconContext.Provider value={{ color: "black", size: "1.5em"}}>
                            <BsDot/> 
                        </IconContext.Provider>
                    )}
                </div>
                <div className="dot-container-1">
                    {(currentSlide === 1) ? (
                        <IconContext.Provider value={{ color: "#00531D", size: "1.5em"}}>
                            <BsDot/> 
                        </IconContext.Provider>
                    ) : (
                        <IconContext.Provider value={{ color: "black", size: "1.5em"}}>
                            <BsDot/> 
                        </IconContext.Provider>
                    )}
                </div>
                <div className="dot-container-2">
                    {(currentSlide === 2) ? (
                        <IconContext.Provider value={{ color: "#00531D", size: "1.5em"}}>
                            <BsDot/> 
                        </IconContext.Provider>
                    ) : (
                        <IconContext.Provider value={{ color: "black", size: "1.5em"}}>
                            <BsDot/> 
                        </IconContext.Provider>
                    )}
                </div>
            </div>
            <div className="right-arrow-container">
                <IconContext.Provider value={{ size: "1.5em"}}>
                    <MdKeyboardArrowRight className="right-arrow" onClick={nextSlide}/>
                </IconContext.Provider>
            </div>
        </div>
    )

}

export default CarouselControl