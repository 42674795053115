import ReactLoading from 'react-loading'
import styled from 'styled-components'

const LoadingCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 200px;
    flex-grow: 1;
    gap: 50px;
    font-size: 20px;
    line-height: 14px;
    letter-spacing: 0.1em;
    font-family: EB Garamond;
`

function LoadingCard({ text, height, width}) {

    return(
        <LoadingCardContainer>
            {text}
            <ReactLoading type={"spinningBubbles"} color={'#00531D'} height={`${height}`} width={`${width}`} />
        </LoadingCardContainer>
    )
}

export default LoadingCard