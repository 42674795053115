import imageCompanyDetails from '../../../static/img/imageCompanyDetails.png'
import imageCompanyDetailsSmall from '../../../static/img/imageCompanyDetailsSmall.svg'
import { useHistory } from 'react-router';
import { layoutGenerator } from 'react-break'
import './Footer.css'

const layout = layoutGenerator({
    tablet: 0,
    desktop: 1270
})

const OnDesktop = layout.is('desktop')
const OnTablet = layout.is('tablet')

function Footer() {

    let history = useHistory();

    const year = new Date().getFullYear()

    function handlePrivacyOnClick() {
        history.push("/privacy")
    }

    return (
        <>
            <OnDesktop>
                <div className="footer-container">
                    <div className="footer-top-line"/>
                    <div className="footer-image-container">
                        <img src={imageCompanyDetails} alt="Grafton Court Wealth Management Ltd"/>
                    </div>
                    <div className="footer-bottom-line"/>
                    <div className="footer-copyright-privacy-link-container">
                        <div className='copyright-privacy-link-container'>
                            <div className='privacy-link-container' onClick={() => handlePrivacyOnClick()}>
                                Privacy Notice
                            </div>
                            <div className="footer-copyright-notice">
                                {`© ${year} Grafton Court Wealth Management Ltd.  All rights reserved.`}
                            </div>
                        </div>
                    </div>
                </div>
            </OnDesktop>

            <OnTablet>
                <div className="footer-container">
                    <div className="footer-top-line-tablet"/>
                    <div className="footer-image-container">
                        <img src={imageCompanyDetailsSmall} alt="Grafton Court Wealth Management Ltd"/>
                    </div>
                    <div className="footer-bottom-line-tablet"/>
                    <div className='privacy-link-container-tablet' onClick={() => handlePrivacyOnClick()}>
                        Privacy Notice
                    </div>
                    <div className="footer-copyright-notice-tablet">
                        {`© ${year} Grafton Court Wealth Management Ltd. All rights reserved`}
                    </div>
                </div>
            </OnTablet>

        </>
    )
}

export default Footer