import HeroCard from '../HeroCard/HeroCard.js'
import './HeroThreeCardRow.css'
import imageAboutUs from '../../../static/img/imageAboutUs.png'
import imageSustainableInvesting from '../../../static/img/imageSustainableInvesting.png'
import imageInsights from '../../../static/img/imageInsights.png'
import imageHeroAboutUsTablet from '../../../static/img/imageHeroAboutUsTablet.png'
import imageHeroSustainabilityTablet from '../../../static/img/imageHeroSustainabilityTablet.png'
import imageHeroInsightsTablet from '../../../static/img/imageHeroInsightsTablet.jpg'
import { layoutGenerator } from 'react-break'
import { useHistory } from 'react-router-dom'

const layout = layoutGenerator({
    mobile: 0,
    tablet: 701,
    desktop: 1050
})

const OnMobile = layout.is('mobile')
const OnTablet = layout.is('tablet')
const OnDesktop = layout.is('desktop')

function HeroThreeCardRow ({scrollCallBackAboutUs=f=>f, scrollCallBackInsights=f=>f, props}) {

    let history = useHistory();


    function sustainbleInvestingRoute() {
        history.push(`/insights/0593beef-be48-4bb1-a20b-9bb3ce9a52d3`)
    }

    const {
        heroCardAboutUsData,
        heroCardSustainableInvestingData,
        heroCardInsightsData
    } = props

    return (
        <>
            <OnDesktop>
                <div className="hero-three-card-horizontal-row-container">
                    <HeroCard image={imageAboutUs} title={heroCardAboutUsData.title} bodyText={heroCardAboutUsData.bodyText} linkText={heroCardAboutUsData.linkText} linkOnClick={scrollCallBackAboutUs}/>
                    <HeroCard image={imageSustainableInvesting} title={heroCardSustainableInvestingData.title} bodyText={heroCardSustainableInvestingData.bodyText} linkText={heroCardSustainableInvestingData.linkText} linkOnClick={sustainbleInvestingRoute}/>
                    <HeroCard image={imageInsights} title={heroCardInsightsData.title} bodyText={heroCardInsightsData.bodyText} linkText={heroCardInsightsData.linkText} linkOnClick={scrollCallBackInsights}/>
                </div>
            </OnDesktop>

            <OnTablet>
                <div className="hero-three-card-horizontal-row-container">
                    <HeroCard image={imageHeroAboutUsTablet} title={heroCardAboutUsData.title} bodyText={heroCardAboutUsData.bodyText} linkText={heroCardAboutUsData.linkText} linkOnClick={scrollCallBackAboutUs}/>
                    <HeroCard image={imageHeroSustainabilityTablet} title={heroCardSustainableInvestingData.title} bodyText={heroCardSustainableInvestingData.bodyText} linkText={heroCardSustainableInvestingData.linkText} linkOnClick={sustainbleInvestingRoute}/>
                    <HeroCard image={imageHeroInsightsTablet} title={heroCardInsightsData.title} bodyText={heroCardInsightsData.bodyText} linkText={heroCardInsightsData.linkText} linkOnClick={scrollCallBackInsights}/>
                </div>
            </OnTablet>

            <OnMobile>
                <div className="hero-three-card-horizontal-row-container">
                    <HeroCard image={imageAboutUs} title={heroCardAboutUsData.title} bodyText={heroCardAboutUsData.bodyText} linkText={heroCardAboutUsData.linkText} linkOnClick={scrollCallBackAboutUs}/>
                    <HeroCard image={imageSustainableInvesting} title={heroCardSustainableInvestingData.title} bodyText={heroCardSustainableInvestingData.bodyText} linkText={heroCardSustainableInvestingData.linkText} linkOnClick={sustainbleInvestingRoute}/>
                    <HeroCard image={imageInsights} title={heroCardInsightsData.title} bodyText={heroCardInsightsData.bodyText} linkText={heroCardInsightsData.linkText} linkOnClick={scrollCallBackInsights}/>
                </div>
            </OnMobile>

        </>
    )
}

export default HeroThreeCardRow