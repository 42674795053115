import AboutUsCard from '../AboutUsCard/AboutUsCard.js'
import { layoutGenerator } from 'react-break'
import './AboutUsThreeCardGrid.css'

const layout = layoutGenerator({
    mobile: 0,
    tablet: 521,
    desktop: 1540,
})

const OnMobile = layout.is('mobile')
const OnTablet = layout.is('tablet')
const OnDesktop = layout.is('desktop')

function AboutUsThreeCardGrid({ props }) {

    const {
        martin,
        mark,
        adam
    } = props

    return (
        <>
            <OnDesktop>
                <div className="about-us-three-card-grid-container-desktop">
                    <AboutUsCard 
                        image={martin.image}
                        name={martin.name}
                        letters={martin.letters}
                        professionalTitles={martin.professionalTitles}
                        bodyText={martin.bodyText}
                    />
                    <AboutUsCard 
                        image={mark.image}
                        name={mark.name}
                        letters={mark.letters}
                        professionalTitles={mark.professionalTitles}
                        bodyText={mark.bodyText}
                    />
                    <AboutUsCard 
                        image={adam.image}
                        name={adam.name}
                        letters={adam.letters}
                        professionalTitles={adam.professionalTitles}
                        bodyText={adam.bodyText}
                    />
                </div>
            </OnDesktop>

            <OnTablet>
                <div className="about-us-three-card-grid-container-tablet">
                    <AboutUsCard 
                        image={martin.image}
                        name={martin.name}
                        letters={martin.letters}
                        professionalTitles={martin.professionalTitles}
                        bodyText={martin.bodyText}
                    />
                    <AboutUsCard 
                        image={mark.image}
                        name={mark.name}
                        letters={mark.letters}
                        professionalTitles={mark.professionalTitles}
                        bodyText={mark.bodyText}
                    />
                    <AboutUsCard 
                        image={adam.image}
                        name={adam.name}
                        letters={adam.letters}
                        professionalTitles={adam.professionalTitles}
                        bodyText={adam.bodyText}
                    />
                </div>
            </OnTablet>

            <OnMobile>
                <div className="about-us-three-card-grid-container-mobile">
                    <AboutUsCard 
                        image={martin.imageMobile}
                        name={martin.name}
                        letters={martin.letters}
                        professionalTitles={martin.professionalTitles}
                        bodyText={martin.bodyText}
                    />
                    <AboutUsCard 
                        image={mark.imageMobile}
                        name={mark.name}
                        letters={mark.letters}
                        professionalTitles={mark.professionalTitles}
                        bodyText={mark.bodyText}
                    />
                    <AboutUsCard 
                        image={adam.imageMobile}
                        name={adam.name}
                        letters={adam.letters}
                        professionalTitles={adam.professionalTitles}
                        bodyText={adam.bodyText}
                    />
                </div>
            </OnMobile>
        </>
    )
}

export default AboutUsThreeCardGrid