import { useHistory } from 'react-router-dom'
import { layoutGenerator } from 'react-break'
import { MdKeyboardArrowRight } from 'react-icons/md'
import { cropText } from '../../../../lib/HelperFunctions'
import './CarouselCardOverlay.css'
import { useMemo } from 'react'


const layout = layoutGenerator({
    mobile: 0,
    phablet: 672,
    tablet: 970,
    desktop: 1530
})

const OnMobile = layout.is('mobile')
const OnPhablet = layout.is('phablet')
const OnTablet = layout.is('tablet')
const OnDesktop = layout.is('desktop')


function CarouselCardOverlay({id, title, bodyText}) {

    const croppedBodyText = useMemo(() => cropText(bodyText, 120), [bodyText]);

    let history = useHistory();

    const readMoreLinkText = "Read More"

    const handleClick = (id) => {
        history.push(`/insights/${id}`)
    }

    return (
        <>
            <OnDesktop>
                <div className="card-overlay-container-desktop">
                    <div className="card-overlay-title-container-desktop">
                        <div className="card-title-text-desktop">{title}</div>
                    </div>
                    <div className="card-overlay-body-text-container-desktop">
                        <div className="card-body-text-desktop">{croppedBodyText}</div>
                    </div>
                    <div className="card-overlay-link-text-desktop" onClick={() => handleClick(id)}>
                            <span>{readMoreLinkText}</span>
                            <span style={{ paddingTop: "8px" }}>
                                <MdKeyboardArrowRight/>
                            </span>
                    </div>
                </div>
            </OnDesktop>

            <OnTablet>
                <div className="card-overlay-container-tablet">
                    <div className="card-overlay-title-container-tablet">
                        <div className="card-title-text-tablet">{title}</div>
                    </div>
                    <div className="card-overlay-link-text-tablet" onClick={() => handleClick(id)}>
                        <span>{readMoreLinkText}</span>
                        <span style={{ paddingTop: "8px" }}>
                            <MdKeyboardArrowRight/>
                        </span>
                    </div>
                </div>
            </OnTablet>

            <OnPhablet>
                <div className="card-overlay-container-phablet">
                    <div className="card-overlay-title-container-phablet">
                        <div className="card-title-text-phablet">{title}</div>
                    </div>
                    <div className="card-overlay-link-text-phablet" onClick={() => handleClick(id)}>
                        <span>{readMoreLinkText}</span>
                        <span style={{ paddingTop: "8px" }}>
                            <MdKeyboardArrowRight/>
                        </span>
                    </div>
                </div>
            </OnPhablet>

            <OnMobile>
                <div className="card-overlay-container-mobile">
                    <div className="card-overlay-title-container-mobile">
                        <div className="card-title-text-mobile">{title}</div>
                    </div>
                    <div className="card-overlay-link-text-mobile" onClick={() => handleClick(id)}>
                        <span>{readMoreLinkText}</span>
                        <span style={{ paddingTop: "8px" }}>
                            <MdKeyboardArrowRight/>
                        </span> 
                    </div>
                </div>
            </OnMobile>
        </>
    )
}

export default CarouselCardOverlay
