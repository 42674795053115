

export function getArticleByIdFromArray(id, articleArray) {
    if (articleArray.length === 0 || articleArray === undefined) {
        return null
    } else {
        const matchingArticle = [];
        articleArray.forEach(article => {
            if (article.articleId === id) {
                matchingArticle.push(article)
            }
        })
        return matchingArticle[0]
    }
}

export function formatText(text) {
    const elements = []
    text.split('\n').forEach(str => {
        const regex = /(?<=<).*(?=>)/g
        if (regex.test(str)) {
            const arrowRegex = /[<>]/g
            elements.push(<><h1>{str.replace(arrowRegex, "")}</h1><br></br></>)
        } else {
            elements.push(<><p>{str}</p><br></br></>)
        }  
    })
    return elements
}

function sliceText(text, limit) {
    const slicedText = text.slice(0, limit);
    return slicedText
}

export function cropText(text, limit) {
    const textRawCropped = sliceText(text, limit);
    const wordArray = textRawCropped.split(" ");
    const lessLastWord = wordArray.slice(0, -1);
    return lessLastWord.join(' ') + "..."
}

