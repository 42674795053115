import { useForm } from 'react-hook-form'
import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import styled from 'styled-components';
import './ContactUsForm.css'
import axios from 'axios';

const EnquirySentMessage = styled.div`
    display: flex;
    align-self: center;
    justify-content: center;
    align-items: center;
    max-width: 591px;
    font-size: 24px;
    text-align: center;
    line-height: 23px;
    letter-spacing: 0.06em;
    font-family: EB Garamond;
    font-style: normal;
    font-weight: normal;
    margin-top: 60px;
    color: #000000;
`

const ErrorMessage = styled.span`
    font-weight: 500;
    font-size: 8px;
    line-height: 16px;
    color: red;
`

const RequiredText = styled.div`
    margin-top: 10px;
    padding: 12px 20px;
`

function ContactUsForm() {

    const [ enquirySent, setEnquirySent ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ responseReceived, setResponseReceived ] = useState("")
    
    const requiredErrorMessage = "*This is required"
    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
            .trim()
            .max(20)
            .required(requiredErrorMessage),
        lastName: Yup.string()
            .trim()
            .max(20)
            .required(requiredErrorMessage),
        email: Yup.string()
            .email('Email is invalid')
            .required(requiredErrorMessage),
        contactNumber: Yup.string()
            .trim()
            .max(11),
        enquiry: Yup.string()
            .max(500)
            .required(requiredErrorMessage)
    })

    const { register, handleSubmit, formState: {errors} } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const sendEnquiry = async (data) => {
            try {
                setLoading(true)
                const response = await axios.post(
                    `https://wmiqworw69.execute-api.eu-west-2.amazonaws.com/enquiry`, 
                    JSON.stringify(data)
                )
                setResponseReceived(response)
                setLoading(false)
            } catch (error) {
                setResponseReceived("error")
                setLoading(false)
            }
    }

    const onSubmit = (data) => {
        sendEnquiry(data)
        setEnquirySent(true)
    }

    return (
        <>
        {(enquirySent) ? (
            <EnquirySentMessage>
                {(loading) ? (
                    <>
                        Sending your enquiry...
                    </>
                ) : (responseReceived === "error") ? (
                    <>
                        <div>
                            Unfortunately there was an error receiving your enquiry.  
                        </div>
                        <div>
                            Please try calling us on +44 (0) 1454 800 506 we would really like to hear from you.
                        </div>
                    </>
                ) : (
                    <>
                        {responseReceived.data}
                    </>
                )}
            </EnquirySentMessage>
        ) : (
            <form className="contact-us-form-container" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-first-row">
                    <div className="field-section" id="field-section-first-name">
                        <label className="label-first-name">
                            First Name*
                        </label>
                        <div className="input-container" id="input-first-name-container">
                            <input className="input" id="input-first-name" placeholder="First Name" {...register("firstName")} />
                            <span>{errors.firstName && <ErrorMessage>{errors.firstName.message}</ErrorMessage>}</span>
                        </div>
                    </div>
                    <div className="field-section" id="field-section-last-name">
                        <label className="label-last-name">
                            Last Name*
                        </label>
                        <div className="input-container" id="input-last-name-container">
                            <input className="input" id="input-last-name" placeholder="Last Name" {...register("lastName")} />
                            {errors.lastName && <ErrorMessage>{errors.lastName.message}</ErrorMessage>}
                        </div>
                    </div>
                </div>
                <div className="form-second-row">
                    <div className="field-section" id="field-section-email">
                        <label className="label-email">
                            Email*
                        </label>
                        <div className="input-container" id="input-email-container">
                            <input className="input" id="input-email" placeholder="Email" {...register("email")} />
                            {errors.email && <ErrorMessage>{errors.email.message}</ErrorMessage>}
                        </div>
                    </div>
                    <div className="field-section" id="field-section-contact-number">
                        <label className="label-contact-number">
                            Telephone / Mobile
                        </label>
                        <div className="input-container" id="input-contact-number-container">
                            <input className="input" id="input-contact-number" placeholder="Telephone / Mobile" {...register("contactNumber")} />
                            {errors.contactNumber && <ErrorMessage>{errors.contactNumber.message}</ErrorMessage>}
                        </div>
                    </div>
                </div>
                <div className="form-third-row">
                    <div className="field-section" id="field-section-enquiry">
                        <label className="label-enquiry">
                            Please let us know your enquiry below*
                        </label>
                        <div className="text-area-container">
                            <textarea className="text-area" rows="7" placeholder="Enter your enquiry here." {...register("enquiry")}></textarea>
                            {errors.enquiry && <ErrorMessage>{errors.enquiry.message}</ErrorMessage>}
                        </div>
                    </div>
                </div>
                <div className="form-forth-row">
                    <RequiredText>* required</RequiredText>
                    <input className="submit-button" type="submit" value="Submit enquiry"/>
                </div>
                <div className="data-protection-row">
                    You voluntarily choose to provide personal details to us via this website.  Personal information will be treated as confidential by us and held in accordance with the Data Protection Act 2018.  You agree that such personal information may be used to provide you with details of services and products in writing, by email or by telephone.
                </div>
            </form>
        )}
        </>
    )
}

export default ContactUsForm