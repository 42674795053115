import { MdKeyboardArrowRight } from 'react-icons/md';
import './HeroCard.css';

function HeroCard({image, title, bodyText, linkText, linkOnClick=f=>f}) {

    return (
        <div className="hero-card-container">
            <div className="hero-card-image-container">
                <img className="hero-card-image" src={image} alt="to enter"/>
            </div>
            <div className="hero-card-title-container">
                <div className="hero-card-title">{title}</div>
            </div>
            <div className="hero-card-body-container">
                <div className="hero-card-body">{bodyText}</div>
            </div>
            <div className="hero-card-link-text-container" onClick={() => linkOnClick()}>
                <div className="hero-card-link">{linkText}</div>
                <span style={{ paddingTop: "10px" }}>
                        <MdKeyboardArrowRight/>
                </span>
            </div>
        </div>
    )
}

export default HeroCard