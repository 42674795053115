import MainHeaderNavMenu from "../MainHeaderNavMenu/MainHeaderNavMenu.js"
import MainHeaderNavMenuMobile from "../MainHeaderNavMenuMobile/MainHeaderNavMenuMobile.js";
import logoHorizontalDark from "../../../static/img/logoHorizontalDark.svg";
import { layoutGenerator } from 'react-break'
import './MainHeaderNav.css'



const layout = layoutGenerator({
    mobile: 0,
    tablet: 970,
    desktop: 1600
})

const OnMobile = layout.is('mobile')
const OnTablet = layout.is('tablet')
const OnDesktop = layout.is('desktop')



function MainHeaderNav({
    scrollCallBackServices=f=>f, 
    scrollCallBackInsights=f=>f,
    scrollCallBackAboutUs=f=>f,
    scrollCallBackContactUs=f=>f
}) {
    return (
        <>
            <OnDesktop>
                <div className="main-header-nav-container-desktop">
                    <div className="main-header-nav-logo-container-desktop">
                        <img src={logoHorizontalDark} alt="Grafton Court Wealth Management Ltd"></img>
                    </div>
                    <MainHeaderNavMenu 
                        scrollCallBackServices={scrollCallBackServices}
                        scrollCallBackInsights={scrollCallBackInsights}
                        scrollCallBackAboutUs={scrollCallBackAboutUs}
                        scrollCallBackContactUs={scrollCallBackContactUs}
                    />
                </div>
            </OnDesktop>

            <OnTablet>
                <div className="main-header-nav-container-tablet">
                    <div className="main-header-nav-logo-container-tablet">
                        <img src={logoHorizontalDark} alt="Grafton Court Wealth Management Ltd"></img>
                    </div>
                    <MainHeaderNavMenu 
                        scrollCallBackServices={scrollCallBackServices}
                        scrollCallBackInsights={scrollCallBackInsights}
                        scrollCallBackAboutUs={scrollCallBackAboutUs}
                        scrollCallBackContactUs={scrollCallBackContactUs}
                    />
                </div>
            </OnTablet>

            <OnMobile>
                <div className="main-header-nav-container-mobile">
                    <div className="main-header-nav-logo-container-mobile">
                        <img src={logoHorizontalDark} alt="Grafton Court Wealth Management Ltd"></img>
                    </div>
                    <MainHeaderNavMenuMobile 
                        scrollCallBackServices={scrollCallBackServices}
                        scrollCallBackInsights={scrollCallBackInsights}
                        scrollCallBackAboutUs={scrollCallBackAboutUs}
                        scrollCallBackContactUs={scrollCallBackContactUs}
                    />
                </div>
            </OnMobile>
        </>
    )
}

export default MainHeaderNav