import { useEffect, useState } from 'react'
import CarouselControl from './CaroselControl/CarouselControl'
import CarouselCardOverlay from './CarouselCardOverlay/CarouselCardOverlay.js';
import { layoutGenerator } from 'react-break'
import './Carousel.css'
import PreloadImage from 'react-preload-image';

const layout = layoutGenerator({
    mobile: 0,
    tablet: 600,
    desktop: 1530
})

const OnMobile = layout.is('mobile')
const OnTablet = layout.is('tablet')
const OnDesktop = layout.is('desktop')

function Carousel ({ slides }) {

    const [intervalId, setIntervalId] = useState(0);
    const [current, setCurrent] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [timerOn, setTimerOn] = useState(false);
    const [playOnStartup, setPlayOnStartUp] = useState(false)
    const length = slides.length

    const nextSlide = () => {
        setCurrent(current === length - 1 ? 0 : current + 1);
    };

    const prevSlide = () => {
        setCurrent(current === 0 ? length - 1 : current - 1);
    };

    const play = () => {
        setTimerOn(true)
    }

    const pause = () => {
        setTimerOn(false)
    }

    function stopInterval() {
        clearInterval(intervalId)
        setIntervalId(0)
        setSeconds(0)
    }

    useEffect(() => {
        if (timerOn === true) {
        const newIntervalId = setInterval(() => {
            setSeconds(
                prevCount => prevCount + 1)
        }, 1000)
        setIntervalId(newIntervalId)
        } else {
            stopInterval()
        }
    }, [timerOn])

    
    useEffect(() => {
        if (playOnStartup === false) {
            setTimerOn(true)
            setPlayOnStartUp(true)
        }
    }, [playOnStartup])

    useEffect(() => {
        if (seconds === 20) {
            nextSlide()
            setSeconds(0)
        }
    },[seconds])
    
    if (!Array.isArray(slides) || slides.length <= 0 ) {
        return null;
    };

    return (
        <>
            <OnDesktop>
                <div className="carousel">
                    {slides.map((slide, index) => {
                        return (
                            <div className={index === current ? 'slide active' : 'slide'} key={index}>
                                {index === current && (
                                    <>  
                                        <div className="image-wrapper">
                                            <PreloadImage 
                                                src={slide.articleDesktopBannerImageLocation} 
                                                alt={"to load"} 
                                                className="imageDesktop"
                                            />
                                        </div>
                                        <CarouselCardOverlay id={slide.articleId} title={slide.articleTitle} bodyText={slide.articleBody} />
                                    </>
                                )}
                            </div>
                        )
                    })}
                </div>
            </OnDesktop>

            <OnTablet>
                <div className="carousel">
                    {slides.map((slide, index) => {
                        return (
                            <div className={index === current ? 'slide active' : 'slide'} key={index}>
                                {index === current && (
                                    <>  
                                        <div className="image-wrapper">
                                            <PreloadImage 
                                                src={slide.articleTabletBannerImageLocation} 
                                                alt={"to load"} 
                                                className="imageTablet"
                                            />
                                        </div>
                                        <CarouselCardOverlay id={slide.articleId} title={slide.articleTitle} bodyText={slide.articleBody} />
                                    </>
                                )}
                            </div>
                        )
                    })}
                </div>
            </OnTablet>

            <OnMobile>
                <div className="carousel">
                    {slides.map((slide, index) => {
                        return (
                            <div className={index === current ? 'slide active' : 'slide'} key={index}>
                                {index === current && (
                                    <>  
                                        <div className="image-wrapper">
                                            <PreloadImage 
                                                src={slide.articleMobileBannerImageLocation} 
                                                alt={slide.alt} 
                                                className="imageMobile"
                                            />
                                        </div>
                                        <CarouselCardOverlay id={slide.articleId} title={slide.articleTitle} bodyText={slide.articleBody} />
                                    </>
                                )}
                            </div>
                        )
                    })}
                </div>
            </OnMobile>
            
            <CarouselControl prevSlide={prevSlide} nextSlide={nextSlide} play={play} pause={pause} currentSlide={current}/>
            
        </>
    );
}

export default Carousel