import logoHorizontalDark from "../../../static/img/logoHorizontalDark.svg";
import { useHistory } from 'react-router-dom'
import "./MainHeaderNavNoMenu.css"

function MainHeaderNavNoMenu() {

    let history = useHistory();

    const homeRoute = () => {
        history.push("/")
    }

    return (
        <div className="main-header-nav-no-menu-container" >
            <div className="main-header-nav-no-menu-logo-container" onClick={() => homeRoute()}>
                <img src={logoHorizontalDark} alt="Grafton Court Wealth Management Ltd" ></img>
            </div>
        </div>
    )
}

export default MainHeaderNavNoMenu

