import './HeroSection.css'


function HeroSection() {

    return (
        <div className="hero-container">
            <div className="hero-title-container">
                <div className="modern-methods-container">
                    Modern Methods.
                </div>
                <div className="traditional-service-container">
                    Traditional Service.
                </div>
            </div>
            <div className="hero-body-text-container">
                At Grafton Court, client care is paramount.  We help individuals, families and companies to manage their wealth by utilising the latest in modern technologies and methods. We pride ourselves in providing highly personalised services building lasting relationships of trust.
            </div>
        </div>
    )
}

export default HeroSection