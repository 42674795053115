import { useRef } from 'react'
import MainHeaderNav from "./MainHeaderNav/MainHeaderNav.js"
import Carousel from "./Carousel/Carousel.js"
import HeroSection from "./HeroSection/HeroSection.js"
import HeroThreeCardRow from "./HeroThreeCardRow/HeroThreeCardRow.js";
import ServicesSixCardGrid from './ServicesSixCardGrid/ServicesSixCardGrid.js'
import RecentInsightsFourCardGrid from './RecentInsightsFourCardGrid/RecentInsightsFourCardGrid.js'
import AboutUsThreeCardGrid from "./AboutUsThreeCardGrid/AboutUsThreeCardGrid.js";
import Footer from './Footer/Footer.js';
import { layoutGenerator } from 'react-break'
import './MainSite.css';
import ContactUsForm from "./ContactUsForm/ContactUsForm.js";
import { useHistory } from 'react-router';


const layout = layoutGenerator({
    mobile: 0,
    tablet: 10,
    desktop: 725
})

const OnTablet = layout.is('tablet')
const OnDesktop = layout.is('desktop')

function MainSite(props) {

    let history = useHistory();

    const {
        heroCardData,
        servicesCardData,
        articles,
        aboutUsData
    } = props;

    const servicesRef = useRef();
    const insightsRef = useRef();
    const aboutUsRef = useRef();
    const contactUsRef = useRef();

    const scrollCallBackServices = () => {
        servicesRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    const scrollCallBackInsights = () => {
        insightsRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    const scrollCallBackAboutUs = () => {
        aboutUsRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    const scrollCallBackContactUs = () => {
        contactUsRef.current.scrollIntoView({ behavior: 'smooth' })
    }

    function handleReadMoreInsightsClick() {
        history.push("/insights")
    }
    
    return (
        <div className="container-center">
            <MainHeaderNav 
                scrollCallBackServices={scrollCallBackServices}
                scrollCallBackInsights={scrollCallBackInsights}
                scrollCallBackAboutUs={scrollCallBackAboutUs}
                scrollCallBackContactUs={scrollCallBackContactUs}
            />
            <Carousel slides={articles.slice(0,3)}/>
            <div className='max-width-layout-central-container'>
            <HeroSection />
            
                <HeroThreeCardRow 
                    scrollCallBackAboutUs={scrollCallBackAboutUs} 
                    scrollCallBackInsights={scrollCallBackInsights}
                    props={heroCardData}
                />

                <OnDesktop>
                    <div ref={servicesRef} className="title-container" >
                        <div className="title-text">Wealth Management Advice Services</div> 
                    </div>
                </OnDesktop>

                <OnTablet>
                    <div ref={servicesRef} className="title-container">
                        <div className="title-text" id="wealth-management">Wealth Management</div>
                        <div className="title-text" id="advice-services">Advice Services</div> 
                    </div>
                </OnTablet>

                <ServicesSixCardGrid scrollCallBackContactUs={scrollCallBackContactUs} props={servicesCardData}/>
                <div ref={insightsRef} className="title-container" id="recent-insights">
                    <div className="title-text">Recent Insights</div>
                </div>
                <RecentInsightsFourCardGrid articles={articles}/>
                <div className="read-more-insights-link-container" onClick={() => handleReadMoreInsightsClick()}>
                    Read more insights
                </div>
                <div ref={aboutUsRef} className="title-container" id="about-us">
                    <div className="title-text">
                        About Us
                    </div>
                </div>
                <AboutUsThreeCardGrid props={aboutUsData}/>
                <div ref={contactUsRef} className="title-container" id="contact-us">
                    <div className="title-text">
                        Contact Us
                    </div>
                </div>
                <ContactUsForm />
            </div>
            <Footer />
        </div>
    );
}

export default MainSite