import ButtonNav from "../../ButtonNav/ButtonNav";
import { layoutGenerator } from 'react-break'
import './MainHeaderNavMenu.css'
import { useHistory } from "react-router-dom";


function MainHeaderNavMenu({
    scrollCallBackServices=f=>f, 
    scrollCallBackInsights=f=>f,
    scrollCallBackAboutUs=f=>f,
    scrollCallBackContactUs=f=>f
}) {

    const layout = layoutGenerator({
        tablet: 0,
        desktop: 1600
    })

    let history = useHistory()
    
    const OnDesktop = layout.is('desktop')
    const OnTablet = layout.is('tablet')
    
    const clientLoginClick = () => {
        history.push(`/client-login`)
    }
    
    const text = "Client Login"

    

    return (
        <>
            <OnDesktop>
                <div className="main-header-nav-menu-container-desktop">
                    <div className="link-container" onClick={() => scrollCallBackServices()}>
                        Services
                    </div>
                    <div className="link-container" onClick={() => scrollCallBackInsights()}>
                        Insights
                    </div>
                    <div className="link-container" onClick={() => scrollCallBackAboutUs()}>
                        About Us
                    </div>
                    <div className="link-container" onClick={() => scrollCallBackContactUs()}>
                        Contact Us
                    </div>
                    <ButtonNav text={text} onClick={clientLoginClick}/>
                </div>
            </OnDesktop>

            <OnTablet>
            <div className="main-header-nav-menu-container-tablet">
                <div className="link-container" onClick={() => scrollCallBackServices()}>
                        Services
                    </div>
                    <div className="link-container" onClick={() => scrollCallBackInsights()}>
                        Insights
                    </div>
                    <div className="link-container" onClick={() => scrollCallBackAboutUs()}>
                        About Us
                    </div>
                    <div className="link-container" onClick={() => scrollCallBackContactUs()}>
                        Contact Us
                    </div>
                    <ButtonNav text={text} onClick={clientLoginClick}/>
                </div>
            </OnTablet>
        </>

    );
}

export default MainHeaderNavMenu;