import { layoutGenerator } from 'react-break'
import { useHistory } from 'react-router'
import { MdKeyboardArrowRight } from 'react-icons/md'
import './InsightsCard.css'
import { cropText } from '../../../../../lib/HelperFunctions'


function InsightsCard({article}) {
    
    let history = useHistory()

    const layout = layoutGenerator({
        mobile: 0,
        tablet: 556,
    })

    const OnMobile = layout.is('mobile')
    const OnTablet = layout.is('tablet')


    function handleClick() {
        history.push(`/insights/${article.articleId}`)
    }

    return (
        <>
            <OnTablet>
                <div className="insights-card-container-tablet">
                    <div className="insights-card-image-tablet">
                        <img className="insights-image" src={article.articleLargeCardImageLocation} alt="to-enter"/>
                    </div>
                    <div className="insights-card-category-tablet">
                        {article.articleCategory}
                    </div>
                    <div className="insights-card-title-tablet">
                        {article.articleTitle}
                    </div>
                    <div className="insights-card-bodyText-tablet">
                        {cropText(article.articleBody, 120)}
                    </div>
                    <div className="insights-card-link-tablet" onClick={() => handleClick()}>
                        <div className="insights-link-container">
                            <span>Read more</span>
                            <span style={{ paddingTop: "8px" }}>
                                <MdKeyboardArrowRight/>
                            </span>
                        </div>
                    </div>
                </div>
            </OnTablet>

            <OnMobile>
                <div className="insights-card-container-mobile">
                    <div className="insights-card-image-mobile">
                        <img className="insights-image" src={article.articleMobileCardImageLocation} alt="to-enter"/>
                    </div>
                    <div className="insights-card-category-mobile">
                        {article.articleCategory}
                    </div>
                    <div className="insights-card-title-mobile">
                        {article.articleTitle}
                    </div>
                    <div className="insights-card-link-mobile" onClick={() => handleClick()}>
                        <div className="insights-link-container">
                            <span>Read more</span>
                            <span style={{ paddingTop: "8px" }}>
                                <MdKeyboardArrowRight/>
                            </span>
                        </div>
                    </div>
                </div>
            </OnMobile>
        </>
    )
}

export default InsightsCard