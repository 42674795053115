import './ButtonNav.css'




function ButtonNav({text, onClick=f=>f}) {

    return (
        <div className="button-nav-container" onClick={() => onClick()}>
            <div className="button-nav-text">{text}</div>
        </div>
    )

}

export default ButtonNav