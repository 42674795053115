import { useEffect, useState } from 'react'
import MainHeaderNavNoMenu from "../../MainHeaderNavNoMenu/MainHeaderNavNoMenu"
import ArticleBody from './ArticleBody/ArticleBody'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import './Article.css'
import Footer from '../../Footer/Footer'
import LoadingCard from '../../../LoadingCard/LoadingCard'
import ScrollToTop from '../../../ScrollToTop'
import ContactUsForm from "../../ContactUsForm/ContactUsForm"

function Article() {

    const [data, setData] = useState({articles:[], isFetching: false});
    const [dataFetched, setDataFetched] = useState(false)
    let { id } = useParams(); 

    useEffect(() => {
        const controller = new AbortController();
        const fetchArticles = async () => {
          try {
            setData({articles: data.articles, isFetching: true});
            const response = await axios.get(`https://wmiqworw69.execute-api.eu-west-2.amazonaws.com/insights/${id}`, {
              signal: controller.signal
            });
            setData({articles: response.data, isFetching: false});
            setDataFetched(true)
          } catch (e) {
            console.log(e);
            setData({articles: data.articles, isFetching: false});
          }
        };
        if (dataFetched === false && data.articles.length === 0) {
          fetchArticles();
        }
        return () => {
          controller.abort();
        }
      }, [dataFetched, data.articles, id]);
    
    return (
        <>
            <ScrollToTop/>
            <div className="article-container-center-horizontal">
                <MainHeaderNavNoMenu/>
                <>
                    <div className="article-layout-grid">
                    {(!dataFetched) ? (
                        <div className="loading-card-container">
                            <LoadingCard text={'Loading article'} height={'100px'} width={'100px'}/>
                        </div>
                    ) : (
                        <>
                            <div className="article-image-wrapper">
                                <img
                                    rel="preload"
                                    src={data.articles[0].articleDesktopBannerImageLocation} 
                                    alt={"image title"}
                                    className="article-image"
                                />
                            </div>
                            <div className="article-body-container">
                                <ArticleBody
                                    category={data.articles[0].articleCategory}
                                    title={data.articles[0].articleTitle}
                                    subtitle={data.articles[0].articleSubtitle}
                                    date={data.articles[0].articleDateCreated}
                                    bodyText={data.articles[0].articleBody}
                                />
                            </div>
                        </>
                    )}
                    </div>
                </>
                <div className="title-container" id="contact-us">
                    <div className="title-text">
                        Contact Us
                    </div>
                </div>
                <ContactUsForm/>
                <Footer/>
            </div>
        </>
    )
}

export default Article