import imageSustainableInvestingLarge from '../../../../../static/img/imageSustainableInvestingLarge.png'


function SustainableInvestingArticleImage({imageLoaded, setImageLoaded=f=>f}) {

    return (
        <img
            src={imageSustainableInvestingLarge}
            alt="large icebergs"
            className="sustainable-investing-article-image"
            style={!imageLoaded ? { visibility: 'hidden'} : {}}
            onLoad={() => setImageLoaded(true)}
        />
    )

}

export default SustainableInvestingArticleImage