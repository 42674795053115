import {useEffect} from "react";
import Footer from "../../Footer/Footer"
import MainHeaderNavNoMenu from "../../MainHeaderNavNoMenu/MainHeaderNavNoMenu"
import './Privacy.css'



function Privacy() {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    
    return(
        <div className="privacy-notice-container">
            <MainHeaderNavNoMenu/>
            <div className="privacy-notice-continer-center">
                <div className="privacy-notice-body-text">
                    <div className="privacy-notice-title">
                        Privacy Notice
                    </div>
                    <div className="privacy-notice-text">
                        <p>This privacy notice explains how we use any personal information we collect about you.</p> 
                        <h1>What information do we collect about you?</h1>
                        <p>We collect information about you when you engage us for financial planning.  This information will relate to your personal and financial circumstances. It may also include special categories of personal data such as data about your health, if this is necessary for the provision of our services.</p> 
                        <p>We may also collect information when you voluntarily complete client surveys or provide feedback to us.</p>  
                        <h1>Information about connected individuals</h1>  
                        <p>We may need to gather personal information about your close family members and dependants in order to provide our service to you effectively. In such cases it will be your responsibility to ensure that you have the consent of the people concerned to pass their information on to us. We’ll provide a copy of this privacy notice for them or, where appropriate, ask you to pass the privacy information to them.</p>  
                        <h1>Why do we need to collect and use your personal data?</h1>  
                        <p>The primary legal basis that we intend to use for the processing of your data is for the performance of our contract with you. The information that we collect about you is essential for us to be able to carry out the services that you require from us effectively. Without collecting your personal data we’d also be unable to fulfil our legal and regulatory obligations.</p> 
                        <p>Where special category data is required we’ll obtain your explicit consent in order to collect and process this information.</p> 
                        <h1>How will we use the information about you?</h1> 
                        <p>We collect information about you in order to provide you with the services for which you engage us.</p> 
                        <h1>Who might we share your information with?</h1> 
                        <p>If you agree, we may email you about other products or services that we think may be of interest to you.</p> 
                        <p>We won’t share your information for marketing purposes with other companies.</p> 
                        <p>In order to deliver our services to you effectively we may send your details to third parties such as those that we engage for professional compliance, accountancy or legal services as well as product and platform providers that we use to arrange financial products for you.</p> 
                        <p>Where third parties are involved in processing your data we’ll have a contract in place with them to ensure that the nature and purpose of the processing is clear, that they are subject to a duty of confidence in processing your data and that they’ll only act in accordance with our written instructions.</p>  
                        <p>Where it’s necessary for your personal data to be forwarded to a third party we’ll use appropriate security measures to protect your personal data in transit. We will use encryption and password protection wherever possible.</p>  
                        <p>To fulfil our obligations in respect of prevention of money-laundering and other financial crime we may send your details to third party agencies for identity verification purposes.</p> 
                        <h1>How long do we keep hold of your information?</h1>  
                        <p>During the course of our relationship with you we’ll retain personal data which is necessary to provide services to you. We’ll take all reasonable steps to keep your personal data up to date throughout our relationship.</p>  
                        <p>We’re also subject to regulatory requirements to retain your data for specified minimum periods. These are, generally:</p> 
                        <ul>
                            <li>Five years for investment business</li>
                            <li>Three years for mortgage business</li>
                            <li>Indefinitely for pension transfers and opt-out business </li>
                            <li>Three years for insurance business</li> 
                        </ul>
                        <p>These are minimum periods, during which we have a legal obligation to retain your records.</p> 
                        <p>Grafton Court Wealth Management Ltd have the right to retain data where we believe it is in our legitimate interest to do so and due to the nature of our business, we will retain all records indefinitely.</p> 
                        <p>You have the right to request deletion of your personal data. We’ll comply with this request, subject to the restrictions of our regulatory obligations and legitimate interests as noted above.</p>  
                        <h1>How can I access the information you hold about me?</h1>  
                        <p>You have the right to request a copy of the information that we hold about you. If you’d like a copy of some or all of your personal information please email or write to us using the contact details noted below.</p> 
                        <p>When your personal data is processed by automated means you have the right to ask us to move your personal data to another organisation for their use.</p>  
                        <p>We have an obligation to ensure that your personal information is accurate and up to date. Please ask us to correct or remove any information that you think is incorrect.</p> 
                        <h1>Marketing</h1>  
                        <p>We’d like to send you information about our products and services which may be of interest to you. If you’ve agreed to receive marketing information, you may opt out at a later date.</p>  
                        <p>You have a right at any time to stop us from contacting you for marketing purposes. If you no longer wish to be contacted for marketing purposes, please contact us by email or post.</p> 
                        <h1>Other websites</h1> 
                        <p>Our website contains links to other websites. This privacy policy only applies to this website so when you link to other websites you should read their own privacy policies.</p>  
                        <h1>What can you do if you are unhappy with how your personal data is processed?</h1> 
                        <p>You also have a right to lodge a complaint with the supervisory authority for data protection. In the UK this is:</p> 
                        <div className="address-block">
                            <p>Information Commissioner's Office</p>
                            <p>Wycliffe House</p> 
                            <p>Water Lane</p> 
                            <p>Wilmslow</p> 
                            <p>Cheshire</p> 
                            <p>SK9 5AF</p>
                        </div> 
                        <h1>Changes to our privacy policy</h1> 
                        <p>We keep our privacy policy under regular review and we will inform you of any changes when they occur. This privacy policy was last updated on 05/06/2024.</p> 
                        <h1>How to contact us</h1> 
                        <p>Please contact us if you have any questions about our privacy policy or information we hold about you: by email at enquiries@gcwm.co.uk</p> 
                        <p>Or write to us at: Grafton Court Wealth Management Ltd, 160 Aztec West, Bristol, BS32 4TU</p> 
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
        
    )
    
}

export default Privacy