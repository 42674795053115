import ServicesCard from '../ServicesCard/ServicesCard'
import { v4 } from "uuid";
import './ServicesSixCardGrid.css'

function ServicesSixCardGrid({scrollCallBackContactUs=f=>f, props}) {

    const {
        servicesCardFinancialWealthPlanning,
        servicesCardInvestmentManagement,
        servicesRetirementPlanning,
        servicesInheritanceTaxPlanning,
        servicesSociallyResponsibleInvesting,
        servicesCorporateWealth
    } = props

    return (
        <div className="services-six-card-grid-container">
            <ServicesCard 
                key={v4()}
                titleLineOne={servicesCardFinancialWealthPlanning.titleLineOne}
                titleLineTwo={servicesCardFinancialWealthPlanning.titleLineTwo} 
                bodyText={servicesCardFinancialWealthPlanning.bodyText}
                onClickContactUs={scrollCallBackContactUs}
            />
            <ServicesCard 
                key={v4()}
                titleLineOne={servicesCardInvestmentManagement.titleLineOne}
                titleLineTwo={servicesCardInvestmentManagement.titleLineTwo} 
                bodyText={servicesCardInvestmentManagement.bodyText}
                onClickContactUs={scrollCallBackContactUs} 
            />
            <ServicesCard 
                key={v4()}
                titleLineOne={servicesRetirementPlanning.titleLineOne}
                titleLineTwo={servicesRetirementPlanning.titleLineTwo} 
                bodyText={servicesRetirementPlanning.bodyText}
                onClickContactUs={scrollCallBackContactUs} 
            />
            <ServicesCard 
                key={v4()}
                titleLineOne={servicesInheritanceTaxPlanning.titleLineOne}
                titleLineTwo={servicesInheritanceTaxPlanning.titleLineTwo} 
                bodyText={servicesInheritanceTaxPlanning.bodyText}
                onClickContactUs={scrollCallBackContactUs} 
            />
            <ServicesCard 
                key={v4()}
                titleLineOne={servicesSociallyResponsibleInvesting.titleLineOne}
                titleLineTwo={servicesSociallyResponsibleInvesting.titleLineTwo} 
                bodyText={servicesSociallyResponsibleInvesting.bodyText}
                onClickContactUs={scrollCallBackContactUs} 
            />
            <ServicesCard 
                key={v4()}
                titleLineOne={servicesCorporateWealth.titleLineOne}
                titleLineTwo={servicesCorporateWealth.titleLineTwo} 
                bodyText={servicesCorporateWealth.bodyText}
                onClickContactUs={scrollCallBackContactUs} 
            />
        </div>
    )
}

export default ServicesSixCardGrid