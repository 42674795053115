import RecentInsightsCard from '../RecentInsightsCard/RecentInsightsCard.js'
import { layoutGenerator } from 'react-break'
import './RecentInsightsFourCardGrid.css'

const layout = layoutGenerator({
    mobile: 0,
    tablet: 556,
    desktop: 1550
})

const OnMobile = layout.is('mobile')
const OnTablet = layout.is('tablet')
const OnDesktop = layout.is('desktop')

function RecentInsightsFourCardGrid({articles}) {

    let articleTopFour = articles.slice(0,4);

    return (
        <>
            <OnDesktop>
                <div className="recent-insights-four-card-grid-container-desktop">
                    {articleTopFour.map((article, index) => (
                        <RecentInsightsCard
                            key={index}
                            image={article.articleLargeCardImageLocation}
                            category={article.articleCategory}
                            title={article.articleTitle}
                            bodyText={article.articleBody}
                            articleId={article.articleId}
                        />
                    ))}
                </div>
            </OnDesktop>

            <OnTablet>
                <div className="recent-insights-four-card-grid-container-tablet">
                    {articleTopFour.map((article, index) => (
                        <RecentInsightsCard
                            key={index}
                            image={article.articleLargeCardImageLocation}
                            category={article.articleCategory}
                            title={article.articleTitle}
                            bodyText={article.articleBody}
                            articleId={article.articleId}
                        />
                    ))}
                </div>
            </OnTablet>

            <OnMobile>
                <div className="recent-insights-four-card-grid-container-mobile">
                    {articleTopFour.map((article, index) => (
                        <RecentInsightsCard
                            key={index}
                            image={article.articleMobileCardImageLocation}
                            category={article.articleCategory}
                            title={article.articleTitle}
                            bodyText={article.articleBody}
                            articleId={article.articleId}
                        />
                    ))}
                </div>
            </OnMobile>
        </>
    )
}

export default RecentInsightsFourCardGrid