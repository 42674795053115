import MainHeaderNavNoMenu from "../../MainHeaderNavNoMenu/MainHeaderNavNoMenu"
import InsightsCard from "./InsightsCard/InsightsCard"
import './Insights.css'
import Footer from "../../Footer/Footer"
import { useEffect, useState } from "react"
import { useLocation } from 'react-router-dom'
import { MdKeyboardArrowDown } from 'react-icons/md'
import axios from 'axios'
import LoadingCard from "../../../LoadingCard/LoadingCard"

function Insights() {

    const [data, setData] = useState({articles:[], isFetching: false});
    const [dataFetched, setDataFetched] = useState(false)

    useEffect(() => {
        const fetchArticles = async () => {
        try {
            setData({articles: data.articles, isFetching: true});
            const response = await axios.get(`https://wmiqworw69.execute-api.eu-west-2.amazonaws.com/insights`);
            setData({articles: response.data, isFetching: false});
            setDataFetched(true)
        } catch (e) {
            console.log(e);
            setData({articles: data.articles, isFetching: false});
        }
        };
        if (dataFetched === false) {
            fetchArticles();
        }
    }, [dataFetched, data.articles]);


    const { pathname } = useLocation();

    const investmentManagement = "Investment Management >"
    const sustainableInvesting = "Sustainable Investing >"
    const assetClassFocus = "Asset Class Focus >"
    const financialPlanning = "Financial Planning >"
    const marketsEconomy = "Markets & Economy >"
    const taxPlanning = "Tax Planning >"

    useEffect(() => {
        window.scrollTo(0,0);
    }, [pathname]);

    return (
        <div className="insights-container-center">
            <MainHeaderNavNoMenu/>
            <div className="insights-title-container">
                Insights
            </div>
            {(!dataFetched) ? (
                <div className="loading-card-container">
                    <LoadingCard text={'Loading article'} height={'100px'} width={'100px'}/>
                </div>
            ) : (
                <>
                <div className="insights-cards-display-container">
                    {data.articles.map((article, index) => {
                        return (
                            <div className="insight-card" key={index}>
                                <InsightsCard article={article}/>
                            </div>
                        )
                    })}
                </div>
                
                {(data.articles.length < 6) ? (
                    <div className="insights-view-more-container"></div>
                ) : (
                    <div className="insights-view-more-container">
                        <div className="view-more"> 
                            view more  
                        </div>
                        <div className="arrow">
                            <MdKeyboardArrowDown/>
                        </div>
                    </div>
                )}
                {/* <div className="categories-banner">
                    <div className="categories-container">
                        <div className="investment-management-container">
                            {investmentManagement}
                        </div>
                        <div className="sustainable-investment-container">
                            {sustainableInvesting}
                        </div>
                        <div className="asset-class-focus-container">
                            {assetClassFocus}
                        </div>
                        <div className="financial-planning-container">
                            {financialPlanning}
                        </div>
                        <div className="markets-and-economy-container">
                            {marketsEconomy}
                        </div>
                        <div className="tax-planning-container">
                            {taxPlanning}
                        </div>
                    </div>
                </div> */}
                
                </>
            )}
                
            <Footer/>
        </div>
    )
}

export default Insights