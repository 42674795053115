import MainHeaderNavNoMenu from "../../MainHeaderNavNoMenu/MainHeaderNavNoMenu";
import imageTransactLogo from "../../../../static/img/imageTransactLogo.jpg";
import imageAbrdnLogo from "../../../../static/img/imageAbrdnLogo.jpg";
import Footer from "../../Footer/Footer"
import './ClientLogin.css'


function ClientLogin() {

    const onTransactLogoClick = () => {
        window.location.href = "https://user.transact-online.co.uk/"
    }

    const onAbrdnLogoClick = () => {
        window.location.href = "https://wrap.abrdn.com"
    }

    return (
        <div className="client-login-container-center">
            <MainHeaderNavNoMenu/>
            <div className="client-login-details-container">
                <div className="client-login-title">
                    Investment Valuation Portals
                </div>
                <div className="client-login-sub-title">
                    Click on the logo which corresponds to your investment platform account
                </div>
                <div className="client-logo-cards-wrapper">
                    <div className="client-login-card">
                        <div className="logo-wrapper">
                            <img className="transact-logo-image" src={imageTransactLogo} alt="Transact Logo" onClick={() => onTransactLogoClick()}/>
                        </div>
                        <div className="login-description">
                            <div style={{paddingBottom: "10px"}}>On clicking the above logo you will be redirected to the Transact platform login area to view your latest valuation.</div>
                            <div>Please be aware that by clicking onto the above link you are leaving the Grafton Court Wealth Management website.  Please note that Grafton Court Wealth Management is not responsible for the accuracy of the information contained within the linked site(s) accessible from this page.</div>
                        </div>
                    </div>
                    <div className="client-login-card">
                        <div className="logo-wrapper">
                            <img className="abrdn-logo-image" src={imageAbrdnLogo} alt="Abrdn Logo" onClick={() => onAbrdnLogoClick()}/>
                        </div>
                        <div className="login-description">
                            <div style={{paddingBottom: "10px"}}>On clicking the above logo you will be redirected to the Abrdn platform login area to view your latest valuation</div>
                            <div>Please be aware that by clicking onto the above link you are leaving the Grafton Court Wealth Management website.  Please note that Grafton Court Wealth Management is not responsible for the accuracy of the information contained within the linked site(s) accessible from this page.</div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>

    )
}

export default ClientLogin